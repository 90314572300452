// extracted by mini-css-extract-plugin
export var active = "TabsWithLinks-module--active--57be4";
export var content = "TabsWithLinks-module--content--f212f";
export var fadeInAnimation = "TabsWithLinks-module--fadeInAnimation--f2d9d";
export var imagesWrap = "TabsWithLinks-module--imagesWrap--854fd";
export var link = "TabsWithLinks-module--link--8286f";
export var linksWrap = "TabsWithLinks-module--linksWrap--dd2b5";
export var parentLink = "TabsWithLinks-module--parentLink--11b98";
export var tabButton = "TabsWithLinks-module--tabButton--ee1b0";
export var tabTitle = "TabsWithLinks-module--tabTitle--f149d";
export var tabs = "TabsWithLinks-module--tabs--ea455";
export var wrapper = "TabsWithLinks-module--wrapper--ee37f";