import { useMemo, useState } from 'react';
import Api from 'api';
import { NEWS_STORAGE_KEY } from 'define/news';

const PAGINATION_CONFIG = { perPage: 3, page: 1 };

export const useNewsPreviewWithPagination = () => {
  const [state, setState] = useState({
    news: [],
    settings: {},
    isLoading: false,
  });

  const endpointQueries = useMemo(() => {
    const params = new URLSearchParams(PAGINATION_CONFIG);
    return params.toString();
  }, [PAGINATION_CONFIG]);

  const fetchNews = lang => {
    const cachedNews = JSON.parse(sessionStorage.getItem(NEWS_STORAGE_KEY)) ?? [];

    if (cachedNews.length) {
      const preparedNews = cachedNews.slice(0, PAGINATION_CONFIG.perPage);
      setState(prev => ({ ...prev, news: preparedNews, isLoading: false }));
    } else {
      setState(prev => ({ ...prev, isLoading: true }));
      Api.getNewsWithPagination(lang, endpointQueries)
        .then(({ data, settings }) => {
          setState(prev => ({
            ...prev,
            settings,
            news: data,
          }));
        })
        .finally(() => setState(prev => ({ ...prev, isLoading: false })));
    }
  };

  return {
    news: state.news,
    settings: state.settings,
    isLoading: state.isLoading,
    fetchNews,
  };
};
