import React from 'react';
import cn from 'classnames';
import Link from 'components/UI/Link';
import { item } from './MobileNavItem.module.scss';

export default function MobileNavItem({ title, url, onClick, className, style }) {
  if (url) {
    return (
      <Link href={url} className={cn(item, className)} style={style}>
        {title}
      </Link>
    );
  }

  return (
    <div role="button" className={cn(item, className)} onClick={onClick} style={style}>
      {title}
    </div>
  );
}
