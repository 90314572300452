import React, { useMemo, useState } from 'react';
import cn from 'classnames';
import { useLocation } from '@reach/router';
import Icon from 'components/UI/Icon';
import Link from 'components/UI/Link';
import SubMenu from '../SubMenu';
import { isRequestedFrom, useLang } from 'utils/localisation';
import { DOMAIN_URL, DOMAIN_URL_AE, DOMAIN_URL_DE, DOMAIN_URL_AZ } from 'define';
import { arabicRegion, azerRegion } from 'define/locale';
import { excludedPages } from './constants';

import * as styles from './LangSwitcher.module.scss';

function LangSwitcher({ isIndexPage, className }) {
  const [showSubmenu, setShowSubmenu] = useState(false);
  const location = useLocation();

  const lang = useLang();
  const langForSwitcher = lang === 'ae' ? 'ar' : lang;

  const submenuLangs = useMemo(() => {
    if (langForSwitcher === 'de') {
      return [
        { title: 'DE', url: `${DOMAIN_URL_DE}` },
        { title: 'EN', url: `${DOMAIN_URL}` },
      ];
    }

    if (langForSwitcher === 'az') {
      return [
        { title: 'AZ', url: `${DOMAIN_URL_AZ}` },
        { title: 'EN', url: `${DOMAIN_URL}` },
      ];
    }

    if (langForSwitcher === 'en' && isRequestedFrom(azerRegion)) {
      return [
        { title: 'EN', url: `${DOMAIN_URL}` },
        { title: 'AZ', url: `${DOMAIN_URL_AZ}` },
      ];
    }

    return [
      { title: 'EN', url: `${DOMAIN_URL}` },
      { title: 'DE', url: `${DOMAIN_URL_DE}` },
    ];
  }, [langForSwitcher, isIndexPage]);

  const singleLangLink = useMemo(() => {
    if (langForSwitcher === 'ar') {
      return `${DOMAIN_URL}`;
    }
    if (isIndexPage && langForSwitcher === 'en' && isRequestedFrom(arabicRegion)) {
      return `${DOMAIN_URL_AE}`;
    }
    return null;
  }, [langForSwitcher, isIndexPage]);

  if (excludedPages.includes(location.pathname) || lang === 'uk') {
    return null;
  }

  return (
    <div className={cn(styles.langSwitcher, className)}>
      <div className={styles.list}>
        {singleLangLink ? (
          <div className={styles.item}>
            <Link key={langForSwitcher} href={singleLangLink} className={styles.linkWithoutArrow}>
              {langForSwitcher === 'ar' ? 'EN' : 'العربية'}
            </Link>
          </div>
        ) : (
          <div
            key={langForSwitcher}
            className={cn(styles.item, styles.submenu, showSubmenu && styles.showSubmenu)}
            onMouseLeave={() => setShowSubmenu(false)}
            onClick={() => setShowSubmenu(!showSubmenu)}
            tabIndex={langForSwitcher}
            role="button"
          >
            <div className={cn(styles.link, styles.arrow)}>
              {langForSwitcher.toUpperCase()}
              <Icon
                name="small-arrow"
                className={cn(styles.arrowIcon, styles.smallArrowIcon, styles.arrowRotated)}
              />
            </div>
            <SubMenu
              items={submenuLangs}
              currentLink={location.href}
              theme="langSwitcher"
              className={styles.submenu}
              withTransition={true}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default LangSwitcher;
