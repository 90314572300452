// extracted by mini-css-extract-plugin
export var bookingButton = "Header-module--bookingButton--08bb2";
export var communicationWrapper = "Header-module--communicationWrapper--0f5ce";
export var contactUsWrapper = "Header-module--contactUsWrapper--e3d6a";
export var fadeInAnimation = "Header-module--fadeInAnimation--37baf";
export var header = "Header-module--header--357cc";
export var hidden = "Header-module--hidden--0c0c8";
export var hidePhone = "Header-module--hidePhone--6c33f";
export var item = "Header-module--item--80dc2";
export var logo = "Header-module--logo--edb3a";
export var logoWrap = "Header-module--logoWrap--e3ae4";
export var mobileHidden = "Header-module--mobileHidden--1ac1a";
export var mobilePanel = "Header-module--mobilePanel--ec3ce";
export var navigation = "Header-module--navigation--acbc9";
export var progressContainer = "Header-module--progressContainer--f7b7b";
export var progressbar = "Header-module--progressbar--9c040";
export var topLine = "Header-module--topLine--d4be9";
export var topLineLayout = "Header-module--topLineLayout--41639";
export var whiteColor = "Header-module--whiteColor--71ac8";