export const getMousePosition = function (mouseEvent) {
  let pos = { x: 0, y: 0 };
  if (mouseEvent.pageX || mouseEvent.pageY) {
    pos = { x: mouseEvent.pageX, y: mouseEvent.pageY };
  } else if (mouseEvent.clientX || mouseEvent.clientY) {
    pos = {
      x: mouseEvent.clientX + document.body.scrollLeft + document.documentElement.scrollLeft,
      y: mouseEvent.clientY + document.body.scrollTop + document.documentElement.scrollTop,
    };
  }
  return pos;
};

export const getRectangle = function (elem) {
  const boundingBox = elem.getBoundingClientRect();
  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return {
    topLeft: {
      x: boundingBox.left + scrollLeft,
      y: boundingBox.top + scrollTop,
    },
    topRight: {
      x: boundingBox.left + scrollLeft + boundingBox.width,
      y: boundingBox.top + scrollTop,
    },
    bottomLeft: {
      x: boundingBox.left + scrollLeft,
      y: boundingBox.top + scrollTop + boundingBox.height,
    },
    bottomRight: {
      x: boundingBox.left + scrollLeft + boundingBox.width,
      y: boundingBox.top + scrollTop + boundingBox.height,
    },
  };
};

export const getTriangleZone = function (menuBox, point) {
  return {
    A: point,
    B: menuBox.bottomLeft,
    C: menuBox.bottomRight,
  };
};

export const isInsideTriangle = function (triangle, point) {
  const { A, B, C } = triangle;

  return (
    checkSameSide(toEdge(A, B), C, point) &&
    checkSameSide(toEdge(A, C), B, point) &&
    checkSameSide(toEdge(B, C), A, point)
  );
};

const checkSameSide = function (edge, point1, point2) {
  return getPolarity(edge, point1) === getPolarity(edge, point2);
};

const getPolarity = function (edge, point) {
  const vectorA = toVector(edge.v1, edge.v2);
  const vectorB = toVector(edge.v1, point);
  const scalar = vectorA.x * vectorB.y - vectorA.y * vectorB.x;

  return scalar >= 0 ? 1 : -1;
};

const toEdge = function (pointA, pointB) {
  return {
    v1: { ...pointA },
    v2: { ...pointB },
  };
};

const toVector = function (pointA, pointB) {
  return {
    x: pointB.x - pointA.x,
    y: pointB.y - pointA.y,
  };
};
