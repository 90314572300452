import React from 'react';
import cn from 'classnames';
import MobileNavItem from './MobileNavItem';
import LangSwitcher from '../../../LangSwitcher/LangSwitcher';
import { itemWrapper, panelItem, switcher, animate } from './MobileNavPanel.module.scss';

export default function MobileNavPanel({
  className,
  parent,
  items,
  isIndexPage,
  enableSwitcher,
  setNextActivePanel,
  open,
}) {
  return (
    <>
      <div className={cn(itemWrapper, className)}>
        {items.map((item, i) => (
          <MobileNavItem
            key={item.title}
            {...item}
            onClick={() => setNextActivePanel({ previous: parent, current: item.title })}
            open={open}
            style={{ '--item-number': i }}
            className={cn(panelItem, open ? animate : undefined)}
          />
        ))}
        {enableSwitcher && !parent && (
          <LangSwitcher
            isIndexPage={isIndexPage}
            className={cn(panelItem, switcher, open ? animate : undefined)}
          />
        )}
      </div>
    </>
  );
}
