import React from 'react';
import cn from 'classnames';

import TabsWithLinks from './components/TabsWithLinks';
import SubmenuLink from './components/SubmenuLink';
import Link from 'components/UI/Link';

import * as styles from './SubMenu.module.scss';

function SubMenu({
  items = [],
  parentLink,
  currentLink,
  active,
  withTransition,
  theme = 'links',
  className,
}) {
  const renderLinks = links => {
    if (links.some(item => Boolean(item.linkList))) {
      return <TabsWithLinks links={links} subMenuActive={active} />;
    }

    return (
      <>
        <div className={styles.linksWrap}>
          {links.map((link, key) => (
            <SubmenuLink
              {...link}
              key={key}
              className={styles.link}
              mod={theme}
              currentLink={currentLink}
            />
          ))}
        </div>
        {parentLink && (
          <Link href={parentLink.url} className={styles.parentLink}>
            {parentLink.title}
          </Link>
        )}
      </>
    );
  };

  return (
    <div
      className={cn(
        styles.subMenu,
        active && styles.visible,
        withTransition && styles.withTransition,
        styles[theme],
        className,
      )}
    >
      <div className={styles.layout}>{renderLinks(items)}</div>
    </div>
  );
}

export default React.memo(SubMenu);
