import React from 'react';
import cn from 'classnames';
import Link from 'components/UI/Link';

import * as styles from './SubmenuLink.module.scss';

const SubmenuLink = ({ className, title, description, url, mod, currentLink }) => {
  const isActive = currentLink?.includes(url);

  return (
    <Link href={url} className={cn(styles.wrapLink, className, styles[mod])}>
      <span className={cn(styles.link, isActive && styles.active)}>{title}</span>
      {description && <p className={styles.description}>{description}</p>}
    </Link>
  );
};

export default SubmenuLink;
