import { graphql, useStaticQuery } from 'gatsby';

export function useHeaderRndVideos() {
  const {
    allRndVideo: { nodes: videos },
  } = useStaticQuery(graphql`
    query {
      allRndVideo(limit: 3) {
        nodes {
          title
          cover {
            url
          }
          videoId
        }
      }
    }
  `);

  return videos;
}
