const _fetch = typeof window === 'undefined' ? require('node-fetch') : window.fetch;
const { serialize } = require('object-to-formdata');
const lang = process.env.GATSBY_LANG || 'en';
const apiVersion = process.env.GATSBY_API_VERSION;

class Request {
  constructor(apiLink) {
    this.apiLink = apiLink;
  }

  request(url, options, body) {
    const req = _fetch(`${this.apiLink}${url}`, {
      headers: {
        'Api-Version': apiVersion,
        'Accept-Language': options.headers?.['Accept-Language'] || lang,
        'Content-Type': 'application/json',
        'Cross-Origin-Resource-Policy': 'cross-origin',
        ...options.headers,
      },
      method: options.method,
      body,
    });

    return req.then(e => e.json());
  }

  get(url, headers) {
    return this.request(url, { method: 'GET', headers });
  }

  post(url, data, headers) {
    return this.request(url, { method: 'POST', headers }, data);
  }

  put(url, headers) {
    return this.request(url, { method: 'PUT', headers });
  }

  postFormData(url, data, opts) {
    const body = serialize(data, {
      indices: true,
      nullsAsUndefineds: true,
    });

    const req = _fetch(`${this.apiLink}${url}`, {
      method: 'POST',
      headers: {
        'Api-Version': apiVersion,
        'Cross-Origin-Resource-Policy': 'cross-origin',
        'Accept-Language': lang,
        Integration: 'site',
      },
      ...opts,
      body,
    });

    return req.then(res => {
      if (!res.ok) {
        return res.text().then(text => {
          throw new Error(`${res.status}: ${text}`);
        });
      }

      return res.json();
    });
  }
}

module.exports = Request;
