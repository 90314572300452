import React, { useState, useRef, useEffect } from 'react';
import cn from 'classnames';
import Link from 'components/UI/Link';
import SubMenu from '../SubMenu';
import { useHistoryCallback } from 'utils/routing';
import { getMousePosition, getRectangle, getTriangleZone, isInsideTriangle } from './functions';

import * as styles from './MainNav.module.scss';

const ItemWrapper = ({ item, children, ...props }) => {
  if (item?.to) {
    return (
      <Link href={item.to} state={{ activeTab: item.key }} {...props}>
        {children}
      </Link>
    );
  }

  return <div {...props}>{children}</div>;
};

const hoverDelay = 100;

function MainNav({ items = [], mods = [], currentLink, isIndexPage }) {
  const [openTab, setOpenTab] = useState();
  const [withTransition, setWithTransition] = useState(true);
  const [inArea, setInArea] = useState(false);
  const [openPosition, setOpenPosition] = useState(null);
  const [timeoutId, setTimeoutId] = useState(null);
  const [pendingOpenTab, setPendingOpenTab] = useState(null);
  const [touchDevice, setTouchDevice] = useState(false);
  const menuRef = useRef(null);
  const subMenus = useRef([]);

  const checkAim = (menuElem, currentPosition) => {
    if (!openPosition) return false;
    const menuBox = getRectangle(menuElem);
    const triangle = getTriangleZone(menuBox, openPosition);

    return isInsideTriangle(triangle, currentPosition);
  };

  const clearPendingOpen = () => {
    if (pendingOpenTab && timeoutId) {
      clearTimeout(timeoutId);
    }
    setPendingOpenTab(null);
  };

  const updateOpen = () => {
    if (!pendingOpenTab || openTab === pendingOpenTab) {
      return;
    }
    const el = subMenus.current[pendingOpenTab].getBoundingClientRect();
    const newPosition = { x: el.x + el.width / 2, y: el.height * 0.25 };
    setOpenPosition(newPosition);
    setOpenTab(pendingOpenTab);
    clearPendingOpen();
  };

  const onItemEnter = (item, withSubmenu) => e => {
    if (touchDevice) {
      setTouchDevice(false);
    }

    if (withSubmenu && openTab) {
      setWithTransition(false);
    }

    clearPendingOpen();
    setPendingOpenTab(item);
  };

  const onItemLeave = () => {
    if (!inArea) {
      setPendingOpenTab(null);
      setTimeoutId(setTimeout(() => updateOpen(), hoverDelay + 100));
    }
  };

  useEffect(() => {
    if (!touchDevice) {
      if (!openTab || !inArea) {
        updateOpen();
      } else {
        setTimeoutId(setTimeout(() => updateOpen(), hoverDelay));
      }
    }
  }, [pendingOpenTab, openTab, inArea, hoverDelay, touchDevice]);

  const onMouseMove = event => {
    if (checkAim(menuRef.current, getMousePosition(event.nativeEvent))) {
      setInArea(true);
    } else {
      setInArea(false);
    }
  };

  const closeMenu = () => {
    clearPendingOpen();
    setOpenTab(null);
    setWithTransition(true);
    setTouchDevice(false);
  };

  // tablet logic
  const handleTabOpen = (tab, withSubmenu) => e => {
    const touch = e.type === 'touchstart';
    setTouchDevice(touch);

    if (withSubmenu && openTab && tab) {
      setWithTransition(false);
    } else {
      setWithTransition(true);
    }

    const shouldClose = !withSubmenu || (touch && e.target.id === tab && openTab === tab);
    if (shouldClose) {
      setOpenTab();
      return;
    }

    setOpenTab(tab);
  };

  useHistoryCallback(closeMenu);

  return (
    <div
      ref={menuRef}
      className={cn(styles.mainNav, ...mods.map(key => styles[key]))}
      onMouseLeave={closeMenu}
      onMouseMove={onMouseMove}
    >
      <div className={styles.list}>
        {items.map(item => {
          const isActive = getActiveTab(item.submenu, currentLink) || currentLink.includes(item.to);
          const isHovered = openTab === item.key;
          const withSubmenu = Boolean(item?.submenu);

          return (
            <div
              ref={el => (subMenus.current[item.key] = el)}
              id={item.key}
              key={item.key}
              className={cn(
                styles.item,
                item?.submenu && styles.submenu,
                isHovered && styles.showSubMenu,
              )}
              tabIndex={item.key}
              role="button"
              onTouchStart={handleTabOpen(item.key, withSubmenu)}
              onMouseEnter={onItemEnter(item.key, withSubmenu)}
              onMouseLeave={onItemLeave}
            >
              <ItemWrapper
                className={cn(
                  styles.link,
                  item?.submenu && styles.arrow,
                  item?.submenu && styles.touchDisabled,
                  !isIndexPage && isActive && styles.current,
                )}
                item={item}
              >
                {item.key}
                {item?.submenu && <div className={cn(styles.arrowIcon, styles.arrowRotated)} />}
              </ItemWrapper>
              {item?.submenu && (
                <SubMenu
                  active={isHovered}
                  theme={item.options?.theme}
                  parentLink={item?.parentLink}
                  currentLink={currentLink}
                  items={item.submenu}
                  withTransition={withTransition}
                />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

function getActiveTab(submenuItems, currentLink) {
  if (!submenuItems) return;

  const submenuLinks = submenuItems?.reduce((acc, item) => {
    if (item.url) {
      acc.push(item.url);
    }

    if (item.parentLink) {
      acc.push(item.parentLink.url);
    }

    if (item.linkList) {
      item.linkList.forEach(link => acc.push(link.url));
    }

    return acc;
  }, []);

  return submenuLinks.includes(currentLink);
}

export default React.memo(MainNav);
