import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import MobileNav from './MobileNav';
import Button from 'components/UI/Button';
import { useTrans, useLang } from 'utils/localisation';
import { lockUnlockPage } from 'utils';

import * as styles from './MobileMenu.module.scss';

const initialState = [{ previous: null, current: null }];

export default function MobileMenu({ links, open, enableSwitcher, isIndexPage }) {
  const lang = useLang();
  const t = useTrans(['header', 'common']);
  const contactUsLink =
    lang === 'ae' || lang === 'az' ? 'https://andersenlab.com/contact-us' : '/contact-us';
  const preparedLinks = composeMobileLinks(links);
  const [state, setState] = useState(initialState);
  const showBackButton = state.length > 1;

  const setNextActivePanel = payload => {
    setState(prev => [...prev, payload]);
  };

  const handleBack = () => {
    if (showBackButton) {
      setState(prev => prev.slice(0, prev.length - 1));
    }
  };

  useEffect(() => {
    if (open) {
      lockUnlockPage.lock();
    } else {
      lockUnlockPage.unlock();
    }
    return () => {
      lockUnlockPage.unlock();
    };
  }, [open]);

  const onTransitionEnd = e => {
    if (!open && e.target.className.includes('mobileMenu') && e.propertyName === 'height') {
      setState(initialState);
    }
  };

  return (
    <div className={cn(styles.mobileMenu, open && styles.open)} onTransitionEnd={onTransitionEnd}>
      <div
        className={cn(styles.backButton, showBackButton && styles.visible)}
        role="button"
        onClick={handleBack}
      >
        {t('back')}
      </div>
      <div className={styles.container}>
        <MobileNav
          links={preparedLinks}
          enableSwitcher={enableSwitcher}
          isIndexPage={isIndexPage}
          state={state}
          setNextActivePanel={setNextActivePanel}
          open={open}
        />
      </div>
      <div className={styles.buttonLine}>
        <Button tag="a" href={contactUsLink} fullWidthMobile>
          {t('contactUs')}
        </Button>
      </div>
    </div>
  );
}

function composeMobileLinks(links) {
  const result = [];

  // prepare existing links data structure for mobile menu
  const preparePanel = (arr, parentTitle, parentLink) => {
    const panel = { parent: null, items: [] };

    if (parentTitle) {
      panel.parent = parentTitle;
    }

    if (Array.isArray(arr)) {
      arr.forEach(item => {
        const res = { title: item.title };
        const withImages =
          Boolean(item.contentType) || item.linkList?.some(({ img }) => Boolean(img));

        if (item.url) {
          res.url = item.url;
        }

        if (item.to && !item.submenu) {
          res.url = item.to;
        }

        if (item.submenu) {
          preparePanel(item.submenu, item.title, item.parentLink);
        }

        if (item.linkList) {
          preparePanel(item.linkList, item.title, item.parentLink);
        }

        if (item.parentLink && !item.to && withImages) {
          res.url = item.parentLink.url;
        }

        const availableInResult = result.find(({ parent }) => parent === parentTitle);
        const alreadyInPanel = availableInResult?.items.find(({ title }) => title === item.title);

        // avoid pushing dublicate links to panel. This is needed mostly for About us panel
        if (!alreadyInPanel && availableInResult) {
          availableInResult.items.push(res);
        } else {
          panel.items.push(res);
        }
      });
    }

    // add parent link (example: All services) at the end of panel
    if (parentLink) {
      panel.items.push(parentLink);
    }

    const availableInResult = result.find(({ parent }) => parent === parentTitle);

    // avoid pushing dublicate panel to result array
    if (availableInResult) return;

    result.push(panel);
  };

  preparePanel(links);

  return result;
}
