import React, { useState } from 'react';
import cn from 'classnames';
import Link from 'components/UI/Link';
import ArticleCards from './ArticleCards';
import RndVideosCards from './RndVideosCards';
import NewsCards from './NewsCards';
import SubmenuLink from './SubmenuLink';
import ImageLink from './ImageLink';
import { useHistoryCallback } from 'utils/routing';

import * as styles from './TabsWithLinks.module.scss';

export default function TabsWithLinks({ links, subMenuActive }) {
  const [selected, setSelected] = useState(links[0].title);

  const [fadeInAnimation, setFadeInAnimation] = useState(false);

  const setDesktopSelectedTab = payload => {
    setSelected(payload);
    setFadeInAnimation(true);
  };

  const onAnimationEnd = () => {
    setFadeInAnimation(false);
  };

  useHistoryCallback(() => {
    setSelected(links[0].title);
  });

  return (
    <div className={styles.wrapper}>
      <div className={styles.tabs}>
        {links.map(({ title }, i) => {
          const isCurrentTab = title === selected;

          return (
            <button
              key={`${title}-${i}`}
              id={`${title}-tab-${i}`}
              onClick={() => setDesktopSelectedTab(title)}
              className={cn(styles.tabButton, isCurrentTab && styles.active)}
              role="tab"
              aria-selected={isCurrentTab}
              aria-controls={`${title}-panel-${i}`}
              tabIndex={isCurrentTab ? 0 : -1}
              disabled={isCurrentTab}
            >
              <p className={styles.tabTitle}>{title}</p>
            </button>
          );
        })}
      </div>
      <div
        className={cn(styles.content, fadeInAnimation && styles.fadeInAnimation)}
        onAnimationEnd={onAnimationEnd}
      >
        {links.map(({ title, linkList, contentType, parentLink }, i) => {
          const isCurrentTab = title === selected;

          return (
            <div
              key={`${title}-${i}`}
              id={`${title}-panel-${i}`}
              role="tabpanel"
              tabIndex="0"
              aria-labelledby={`${title}-tab-${i}`}
              hidden={!isCurrentTab}
            >
              {renderLinks(contentType, linkList, subMenuActive)}
              {parentLink && (
                <Link href={parentLink.url} className={styles.parentLink}>
                  {parentLink.title}
                </Link>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

function renderLinks(contentType, linkList, subMenuActive) {
  if (contentType === 'articles') {
    return <ArticleCards />;
  }

  if (contentType === 'rnd-videos') {
    return <RndVideosCards />;
  }

  if (contentType === 'news') {
    return <NewsCards subMenuActive={subMenuActive} />;
  }

  const withImages = linkList?.some(item => Boolean(item.img));
  return (
    <div className={cn(styles.linksWrap, withImages && styles.imagesWrap)}>
      {linkList?.map((link, key) => {
        if (link.img) {
          return <ImageLink key={key} {...link} dir="rtl" />;
        }
        return <SubmenuLink key={key} {...link} className={styles.link} />;
      })}
    </div>
  );
}
