import React, { lazy, Suspense } from 'react';
import Header from 'components/Header';
import Footer from 'components/Footer';
import CookiesPolicy from 'components/CookiesPolicy';

const SuccessModal = lazy(() => import('components/Modals/SuccessModal'));
const ErrorModal = lazy(() => import('components/Modals/ErrorModal'));
const ConsentModal = lazy(() => import('components/Modals/ConsentModal'));
const LangNotificationModal = lazy(() => import('components/Modals/LangNotificationModal'));

function PageLayout({ children }) {
  return (
    <>
      <Header />
      {children}
      <Footer />
      <Suspense fallback={null}>
        <SuccessModal />
        <ErrorModal />
        <ConsentModal />
        <LangNotificationModal />
      </Suspense>
      <CookiesPolicy />
    </>
  );
}

export default PageLayout;
