import React from 'react';
import LocalisedPhone from 'components/LocalisedPhone';
import Icon from 'components/UI/Icon';
import { useLang } from 'utils/localisation';
import * as styles from './Contacts.module.scss';

function Contacts({ className }) {
  const lang = useLang();

  return (
    <div className={className}>
      <LocalisedPhone defaultLocale={lang}>
        {phone => (
          <a href={`tel:${phone}`} className={styles.link}>
            <Icon name="phone-filled" className={styles.iconPhone} />
            {phone}
          </a>
        )}
      </LocalisedPhone>
      <a href="mailto:vn@andersenlab.com" className={styles.link}>
        <Icon name="mail" className={styles.iconMail} />
        vn@andersenlab.com
      </a>
    </div>
  );
}

export default Contacts;
