import { graphql, useStaticQuery } from 'gatsby';

export function useHeaderArticles() {
  const {
    allContentfulArticlePreview: { nodes: articles },
  } = useStaticQuery(graphql`
    query {
      allContentfulArticlePreview(
        filter: { node_locale: { eq: "en" }, previewImage: { url: { ne: null } } }
        sort: { publishDate: DESC }
        limit: 3
      ) {
        nodes {
          previewImage {
            url
          }
          previewTitle
          articleLink
        }
      }
    }
  `);

  return articles;
}
