import { useCallback, useEffect, useState, useRef } from 'react';
import { useInView } from 'react-intersection-observer';

export const useIsSsr = () => {
  // we always start off in "SSR mode", to ensure our initial browser render
  // matches the SSR render
  const [isSsr, setIsSsr] = useState(true);

  useEffect(() => {
    // `useEffect` never runs on the server, so we must be on the client if
    // we hit this block
    setIsSsr(false);
  }, []);

  return isSsr;
};

export const useTooltipState = (dependencies = []) => {
  const [isOpen, setIsOpen] = useState(false);

  const showTooltip = useCallback(() => setIsOpen(true), dependencies);
  const hideTooltip = useCallback(() => setIsOpen(false), dependencies);

  return { isOpen, showTooltip, hideTooltip };
};

export const useStateModal = () => {
  const [modalState, setModalState] = useState({ isOpen: false });

  const showModal = useCallback(() => setModalState({ isOpen: true }));
  const hideModal = useCallback(() => setModalState({ isOpen: false }));

  return { modalState, showModal, hideModal };
};

export const useStateModals = () => {
  const [modalState, setModalState] = useState({});

  const showModal = useCallback(key => setModalState({ ...modalState, [key]: true }));
  const hideModal = useCallback(key => () => setModalState({ ...modalState, [key]: false }));

  return { modalState, showModal, hideModal };
};

export function useLoadingComponent(ApiCall, ...args) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  useEffect(() => {
    ApiCall(...args)
      .then(setData)
      .finally(() => setLoading(false));
  }, []);

  return { data, loading };
}

export function useDefferedCallback(callback, options = {}) {
  const defaultDelay = 5000;
  const { delay, rootMargin, initialInView } = options;
  const preparedDelay = delay ?? defaultDelay;

  const [timeoutId, setTimeoutId] = useState(null);
  const [triggered, setTriggered] = useState(false);
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
    rootMargin,
    initialInView,
  });

  const triggerCallback = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
      setTimeoutId(null);
    }
    if (!triggered) {
      callback();
      setTriggered(true);
    }
  };

  useEffect(() => {
    if (inView) {
      triggerCallback();
    } else if (!inView && !triggered) {
      window.addEventListener(
        'load',
        () => setTimeoutId(setTimeout(triggerCallback, preparedDelay)),
        {
          once: true,
        },
      );
    }
  }, [inView, triggered, timeoutId, preparedDelay]);

  return { inViewRef };
}

export const useClickOutside = (refs, handler) => {
  useEffect(() => {
    const targets = refs.map(el => el.current);

    const listener = e => {
      if (
        !targets.some(el => el?.contains && el.contains(e.target)) &&
        typeof handler === 'function'
      ) {
        handler();
      }
    };

    document.addEventListener('mouseup', listener);
    document.addEventListener('touchend', listener);
    return () => {
      document.removeEventListener('mouseup', listener);
      document.removeEventListener('touchend', listener);
    };
  }, [refs, handler]);
};

export const useWindowResize = () => {
  const [width, setWidth] = useState(null);

  useEffect(() => {
    setWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return { width };
};

export function useHorizontalScroll(deps) {
  const scrollRef = useRef();
  const [scrollLeft, setScrollLeft] = useState(null);

  useEffect(() => {
    const el = scrollRef.current;

    if (el) {
      const onWheel = e => {
        const scrollIsNotNeeded = el.clientWidth === el.scrollWidth;
        setScrollLeft(el.scrollLeft);

        if (e.deltaY === 0) return;
        if (scrollIsNotNeeded) return;

        e.preventDefault();

        el.scrollTo({
          left: el.scrollLeft + e.deltaY,
        });
      };

      el.addEventListener('wheel', onWheel);

      return () => {
        el.removeEventListener('wheel', onWheel);
      };
    }
  }, [deps]);

  return { scrollRef, scrollLeft, setScrollLeft };
}
