import React from 'react';
import { Link as LinkOriginal } from 'gatsby';
import { observer, modal as modalStore } from 'store';

function LinkGlobal({ className, href = '', children, onClick, state, target, ...other }) {
  if (
    href.startsWith('https://andersenlab.com') ||
    href.startsWith('https://people.andersenlab.com')
  ) {
    const handleClick = e => {
      e.preventDefault();
      modalStore.open('LangNotificationModal', { href, target });
    };

    return (
      <>
        <a className={className} onClick={e => handleClick(e)} href={href} {...other}>
          {children}
        </a>
      </>
    );
  }

  if (href.startsWith('/')) {
    return (
      <LinkOriginal onClick={onClick} className={className} to={href} state={state} {...other}>
        {children}
      </LinkOriginal>
    );
  }

  return (
    <a
      className={className}
      onClick={onClick}
      href={href}
      target="_blank"
      rel="noreferrer noopener noindex nofollow"
      {...other}
    >
      {children}
    </a>
  );
}

export default observer(LinkGlobal);
