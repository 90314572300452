import React from 'react';
import cn from 'classnames';
import MobileNavPanel from './components/MobileNavPanel';
import * as styles from './MobileNav.module.scss';

export default function MobileNav({
  links,
  enableSwitcher,
  isIndexPage,
  state,
  setNextActivePanel,
  open,
}) {
  const activePanel = state[state.length - 1].current;

  return (
    <nav className={styles.mobileNav}>
      {links.map((item, i) => {
        const previousPanel = state.some(panel => panel.current === item.parent);
        return (
          <MobileNavPanel
            key={i}
            className={cn(
              styles.panel,
              previousPanel && styles.parent,
              activePanel === item.parent && styles.visible,
            )}
            {...item}
            enableSwitcher={enableSwitcher}
            isIndexPage={isIndexPage}
            active={activePanel === item.parent}
            setNextActivePanel={setNextActivePanel}
            open={open}
          />
        );
      })}
    </nav>
  );
}
