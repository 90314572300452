// extracted by mini-css-extract-plugin
export var button = "SubMenu-module--button--f599e";
export var description = "SubMenu-module--description--109af";
export var fadeInAnimation = "SubMenu-module--fadeInAnimation--8e9e7";
export var langSwitcher = "SubMenu-module--langSwitcher--1e9d0";
export var layout = "SubMenu-module--layout--dfd95";
export var link = "SubMenu-module--link--6b5df";
export var links = "SubMenu-module--links--6230b";
export var linksWrap = "SubMenu-module--linksWrap--b10ce";
export var list = "SubMenu-module--list--17e8e";
export var parentLink = "SubMenu-module--parentLink--eda9c";
export var subMenu = "SubMenu-module--subMenu--b84ba";
export var title = "SubMenu-module--title--f0215";
export var visible = "SubMenu-module--visible--8e887";
export var withTransition = "SubMenu-module--withTransition--dcdd9";