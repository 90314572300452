import React from 'react';
import Link from 'components/UI/Link';
import Image from 'components/UI/Image';
import { useLang } from 'utils/localisation';

import * as styles from './ImageLink.module.scss';

export default function ImageLink({ img, url, title, date, onClick, dir = 'ltr' }) {
  const Wrapper = url ? Link : 'div';
  const lang = useLang();
  const direction = lang === 'ae' && dir === 'rtl' ? 'rtl' : 'ltr';

  return (
    <Wrapper className={styles.item} href={url} onClick={onClick} dir={direction}>
      <Image
        src={img}
        className={styles.picture}
        classImage={styles.img}
        alt={`${title} preview`}
      />

      <div className={styles.content}>
        {date && <p className={styles.date}>{date}</p>}
        <p className={styles.title}>{title}</p>
      </div>
    </Wrapper>
  );
}
