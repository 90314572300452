import React, { useState, useEffect, useLayoutEffect, useRef, useMemo } from 'react';
import cn from 'classnames';
import Container from 'components/UI/Container';
import Button from 'components/UI/Button';
import Markup from 'components/Markup';
import { observer, cookies as cookiesStore, modal as modalStore } from 'store';
import { CONSENT_COOKIE_NAME, CONSENT_MODAL_ID, getCookieOptions } from 'define/cookies';
import { setCookie, getCookie } from 'utils/cookie';
import { euroMoneyRegion } from 'define/locale';
import { isRequestedFrom, useTrans, useLang, getCountryCode } from 'utils/localisation';
import { pushCookiePolicyEvent } from 'utils/analytics';
import * as styles from './CookiesPolicy.module.scss';

const BANNER = 'banner';

function CookiesPolicy() {
  const descriptionRef = useRef();
  const lang = useLang();
  const [bannerTheme, setBannerTheme] = useState(null);
  const t = useTrans(['consentBanner']);
  const content = t(bannerTheme);
  const [fullDescription, setFullDescription] = useState(false);
  const [consent, setConsent] = useState({ ad_storage: true, analytics_storage: true });

  const acceptCookies = () => {
    setCookie(CONSENT_COOKIE_NAME, JSON.stringify(consent), getCookieOptions());
    pushCookiePolicyEvent();
  };

  const onAccept = () => {
    if (bannerTheme === 'gdpr') {
      acceptCookies();
    }
    if (bannerTheme === 'small') {
      localStorage.setItem(BANNER, 'seen');
    }
    cookiesStore.setSeen(true);
  };

  const onManage = () => {
    modalStore.open(CONSENT_MODAL_ID);
    cookiesStore.setSeen(true);
  };

  useLayoutEffect(() => {
    const country = getCountryCode();
    const consentStatus = JSON.parse(getCookie(CONSENT_COOKIE_NAME));
    if (consentStatus) {
      setConsent(prev => ({ ...prev, ...consentStatus }));
    }

    if (lang === 'ae' || lang === 'az') {
      setBannerTheme('small');
      return;
    }
    if (lang === 'de') {
      setBannerTheme('gdpr');
      return;
    }
    if (!country) {
      setBannerTheme('gdpr');
      return;
    }
    if (isRequestedFrom([...euroMoneyRegion, 'gb'])) {
      setBannerTheme('gdpr');
      return;
    }

    setBannerTheme('small');
  }, []);

  useLayoutEffect(() => {
    cookiesStore.setTheme(bannerTheme);

    if (bannerTheme === 'gdpr' && !getCookie(CONSENT_COOKIE_NAME)) {
      cookiesStore.setSeen(false);
    } else if (bannerTheme === 'small' && !getCookie(CONSENT_COOKIE_NAME)) {
      acceptCookies();
      if (!localStorage.getItem(BANNER)) {
        cookiesStore.setSeen(false);
      }
    }
  }, [bannerTheme]);

  const handleClick = href => e => {
    e.preventDefault();
    modalStore.open('LangNotificationModal', { href });
  };

  useEffect(() => {
    if (lang === 'ae') {
      const links = descriptionRef.current?.querySelectorAll('a');

      links.forEach(element => {
        element.onclick = handleClick(element.href);
      });
    }
  }, [descriptionRef.current]);

  const finalDescription = useMemo(() => {
    if (!fullDescription) {
      return content?.description.slice(0, content?.description.indexOf('\r'));
    }

    return content?.description;
  }, [content, fullDescription]);

  return (
    <div className={cn(styles.wrapper, styles[bannerTheme], cookiesStore.seen && styles.hidden)}>
      <Container padding="Without" background="transparent" className={styles.container}>
        <div className={styles.contentWrapper}>
          <div className={styles.content}>
            <div>
              <Markup ref={descriptionRef} tag="span" className={styles.text}>
                {finalDescription}
              </Markup>
              {bannerTheme === 'gdpr' && content?.showMore && (
                <button
                  onClick={() => setFullDescription(!fullDescription)}
                  className={cn(styles.showMore, fullDescription && styles.active)}
                >
                  {!fullDescription ? content?.showMore : content?.hide}
                </button>
              )}
            </div>
          </div>
          {content?.manage && (
            <button className={styles.manageButton} onClick={onManage}>
              {content?.manage}
            </button>
          )}
          <Button className={styles.button} onClick={onAccept} fullWidthMobile>
            {content?.accept}
          </Button>
        </div>
      </Container>
    </div>
  );
}

export default observer(CookiesPolicy);
